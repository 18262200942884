/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/
@import 'custom';

body {
  font-family: $font-stack-primary;
  font-size: $font-size;
  background: $bg-body;
  color: $color-dark;
}

html, body {
  height: 100%;
}

h1 {
  font-size: $h1-font-size;
}

h2 {
  font-size: $h2-font-size;
}

h3 {
  font-size: $h3-font-size;
}

h4 {
  font-size: $h4-font-size;
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;

  font-family: $font-stack-secondary;
  font-weight: 400;
}

p {
  line-height: $p-line-height;
}

a {
  color: #000000;
}

a:hover,
a:active {
  color: #000000;
  text-decoration: none;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

/*** Buttons Start ***/

.btn {
  position: relative;

  //display: inline-flex;
  //justify-content: center;
  //align-items: center;

  line-height: calc(3rem - 1rem - 2px);
  padding: 0.65rem 2rem 0.5rem;

  font-size: 1.125em;
  font-weight: 400;

  transform: translate3d(0, 0, 0);
  .fake-hover {
    padding: inherit;
  }
  &-primary,
  &-primary:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
  &-primary:hover,
  &-primary:active:focus {
    background-color: $color-primary;
    border-color: $color-primary;
    color: white;
  }
  &-accent,
  &-accent:focus {
    background-color: $color-accent;
    border-color: $color-accent;
    color: white;
  }
  &-accent:hover,
  &-accent:active:focus {
    background-color: $color-accent;
    border-color: $color-accent;
    color: white;
  }
  &-white,
  &-white:focus {
    background-color: white;
    border-color: white;
    color: $color-dark;
  }
  &-white:hover,
  &-white:active:focus {
    background-color: white;
    border-color: white;
    color: $color-dark;
  }
}

.btn:hover {
  .original-hover div {
    transform: translate3d(0, -10px, 0);
    opacity: 0;
  }
  .fake-hover div {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/*** Buttons End ***/

/*** Misc Start ***/

@for $i from 1 through 10 {
  .mt-#{$i} {
    margin-top: $i / 2 + rem !important;
  }
  .mb-#{$i} {
    margin-bottom: $i / 2 + rem !important;
  }
}

.o {
  @for $i from 1 through 10 {
    &-#{$i} {
      opacity: 0.1 * $i;
    }
  }
}

.section-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

img[data-bg="true"] {
  display: none;
}

.section {
  position: relative;
  padding: 5rem 0;
  > .d-flex {
    margin: -5rem 0;
    padding: 5rem 0;
  }
}

//Grid mods
$grid-gutter: 15px;

.container {
  width: 100%;

  max-width: 1200px;

  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.container {
  padding-left: $grid-gutter;
  padding-right: $grid-gutter;
}

.row {
  margin-left: -$grid-gutter;
  margin-right: -$grid-gutter;
  > .col,
  > [class*="col-"] {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }
}

.container-fluid {
  width: 100%;
}

.overflow-h {
  overflow: hidden;
}

.bg-white {
  background-color: white;
}

*:focus {
  outline: 0 !important;
}

.bg-light {
  background-color: $color-light !important;
}

.bg-primary {
  background-color: $color-primary !important;
  color: white;
}

/*** Misc End ***/

/*** Header Start ***/

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-expand-md {
  background-color: transparent;

  border: 0;
  border-radius: 0;

  margin: 0;
  z-index: 1051;
}

.navbar-nav {
  .nav-item > .nav-link {
    position: relative;
    //font-family: $font-stack-secondary;
  }
  .nav-item > .nav-link:hover {
    background-color: transparent;
    .original-hover div {
      transform: translate3d(0, -10px, 0);
      opacity: 0;
    }
    .fake-hover div {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  .nav-item > .nav-link:focus {
    background-color: transparent;
  }
}

.navbar-brand {
  font-family: $font-stack-secondary;
  font-weight: 400;
  font-size: 1.5em;
}

.navbar-invert {
  .navbar-nav .nav-item > .nav-link {
    color: white;
  }
  .navbar-brand {
    color: white;
  }
}

/*** Header End ***/

/*** Hero Start ***/

.hero {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-dark;
  color: white;
  > .d-flex {
    width: 100%;
    padding: 5rem 0;
    padding-top: calc(2.5rem + #{$header-padding});
    padding-bottom: calc(5rem + 3rem);
  }
  > .d-flex > .d-flex {
    width: 100%;
  }
  h1 {
    line-height: 1.125;
  }
}

#hero-home {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $color-dark;
  color: white;
  .bg-video {
    opacity: 0.25;
  }
}

.hero-caption {
  h1 {
    font-weight: 600;
    @include boxed(50rem);
  }
  h2 {
    line-height: $p-line-height;
    font-family: $font-stack-primary;
    font-size: 1.125em;
    font-weight: 300;
    margin-top: 1rem;
    @include boxed(50rem);
  }
  .btn {
    margin-top: 2rem;
  }
}

/*** Hero End ***/

/*** Main Start ***/

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg-video-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
}

.section-content {
  h2 {
    font-size: 2em;
    font-weight: 600;
    @include boxed(50rem);
  }
  * + p {
    margin-top: 1.25rem;
  }
  p {
    margin-bottom: 0;

    font-size: 1em;
    font-weight: 300;

    color: rgba($color-dark, 0.75);
    @include boxed(50rem);
  }
  p + p {
    margin-top: 1rem;
  }
  .btn {
    margin-top: 2rem;
  }
}

.section-heading {
  h2 {
    font-size: 2em;
    font-weight: 600;
    @include boxed(50rem);
  }
  p {
    font-weight: 300;
    font-size: 1em;

    margin-top: 1rem;
    margin-bottom: 0;

    line-height: 1.525;

    opacity: 0.6;
    @include boxed(50rem);
  }
}

.how {
  display: flex;
  flex-flow: row wrap;
  margin: -15px;
  &-item {
    padding: 15px;
    @include flex-grid(1);
  }
  &-block {
    position: relative;

    border: 1px solid white;
    border-top: 0;

    display: flex;
    flex-direction: column;

    min-height: 100%;
  }
  &-block-header {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    transform: translateY(-50%);
    margin: 0 -2px;
    h6 {
      font-size: 1.5em;
    }
    &:before,
    &:after {
      content: "";
      flex: 1;

      height: 1px;
      background-color: white;
    }
    &-inner {
      display: inline-block;
      padding: 0 1rem;
    }
  }
  &-block-content {
    padding: 0.5rem 2rem 1.5rem;
    text-align: justify;

    flex: 1;
    p {
      margin-bottom: 1rem;
    }
  }
}

#section-how {
  .section-bg-image {
    filter: grayscale(100%);
  }
}

.clients {
  display: flex;
  flex-flow: row wrap;
  &-item {
    @include flex-grid(2);
  }
  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-block {
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
  }
  &-item:nth-child(-n + 2) &-block {
    border-top: 0;
  }
  &-item:nth-child(2n + 2) &-block {
    border-right: 0;
  }
}

.logo {
  width: 10rem;
  height: 10rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-width: 100%;
  max-height: 6rem;
}

.swiper-testimonials-wrapper {
  position: relative;
  width: 100%;
  @include  boxed(60rem);
  .swiper-button-disabled {
    opacity: 0.2;
  }
}

.swiper-testimonials {
  position: relative;
  overflow: hidden;
}

.swiper-btn-prev,
.swiper-btn-next {
  width: 3rem;
  height: 3rem;
  background: $color-primary;
  color: white;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  .chevron {
    width: 0.5rem;
    height: 0.5rem;
  }
  .chevron-left:before {
    left: 0.125rem;
  }
  .chevron-right:before {
    left: -0.125rem;
  }
}

.chevron {
  position: relative;
}

.chevron:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-color: currentColor;
  border-width: 2px 2px 0 0;
  width: 100%;
  height: 100%;
}

.chevron-left:before {
  transform: rotate(-135deg);
}

.chevron-right:before {
  transform: rotate(45deg);
}

.testimonial {
  text-align: center;
  &-content p {
    font-weight: 300;
    margin-bottom: 0;
  }
  &-cite {
    margin-top: 2rem;
    cite {
      display: inline-block;
      font-family: $font-stack-secondary;
      font-style: normal;
    }
  }
}

#section-contact {
  background-color: $color-primary;
  color: white;
  a:not(.btn) {
    color: inherit;
  }
  .form-wrapper {
    @include boxed(30rem);
  }
  .form-control {
    background: lighten($color-primary, 10%);
    color: white;
  }
}

.form-wrapper {
  padding: 2rem;
  background: lighten($color-primary, 5%);
}

.form-horizontal {
  label {
    font-weight: 500;
    font-size: 0.75em;
    margin-bottom: 0.25rem;
  }
}

.form-control {
  height: 3rem;
  padding: 0.75rem 1rem;
  border-radius: 3px;
  border: 0;
  box-shadow: none;
  &:focus {
    box-shadow: none;
  }
}

.contact {
  margin-bottom: 0;
  & + & {
    margin-top: 1rem;
  }
  strong {
    font-family: $font-stack-secondary;
    font-weight: 400;
    font-size: 1.25em;
    display: block;
  }
  a:hover {
    text-decoration: underline;
  }
}

div.blast {
  display: inline-block;
  word-wrap: break-word;
}

div.blast-sentence {
  display: block;
  word-wrap: break-word;
}

.fake-hover {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  white-space: nowrap;
  pointer-events: none;
  div {
    position: relative;

    opacity: 0;
    transform: translate3d(0, 10px, 0);

    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.original-hover {
  div {
    position: relative;

    transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.tag {
  display: inline-block;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 0.75em;
}

.nav-tabs-services {
  border-bottom: 0;
  justify-content: center;
  margin-bottom: 4rem;
  .nav-link {
    border: 0;
    background-color: lighten($color-accent, 10%);
    color: $color-primary;
    border-radius: 0;
    padding: 0.75rem 1.5rem;

    transition: all 200ms ease;
  }
  .nav-link.active {
    background-color: $color-primary;
    color: white;
  }
}

.services-grid {
  display: flex;
  flex-flow: row wrap;
  margin: -15px;
  margin-bottom: 1rem;
  &-item {
    @include flex-grid(3);
    padding: 15px;
  }
}

.services-block {
  //text-align: center;
  h6 {
    font-weight: 600;
    font-size: 1.5em;
  }
  p {
    margin-bottom: 0;
    margin-top: 0.75rem;
    font-weight: 300;
  }
}

.note {
  font-style: italic;
  color: rgba($color-dark, 0.6);
}

.note-asterisk {
  color: #ff0000;
}

.scroll {
  position: absolute;
  overflow: hidden;

  width: 3rem;
  height: 3rem;

  color: white;
  border: 1px solid white;
  border-radius: 50%;

  left: 50%;
  bottom: 3rem;

  transform: translate(-50%, 0);
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  i {
    transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  i:nth-child(1) {
    position: relative;
  }
  i:nth-child(2) {
    position: absolute;
    top: 0;
    transform: translateY(-2rem);
  }
  &:hover i:nth-child(1) {
    transform: translateY(4rem);
  }
  &:hover i:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
    transition-delay: 300ms;
  }
  &:hover,
  &:focus {
    color: white;
  }
}

.bg-fill-v {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 100%;
  min-height: 320px;
}

.ul-spaced {
  list-style: none;
  padding: 0;
  margin: 0;
  counter-reset: ul-counter;
  li {
    counter-increment: ul-counter;
    position: relative;
    padding: 1.25rem 1rem 1.25rem 3rem;
    //font-size: 1.125em;
    font-weight: 300;
    color: rgba($color-dark, 0.6);
    border-top: 1px solid #eaeaea;
  }
  li:first-child {
    border-top: 0;
  }
  li:before {
    content: counter(ul-counter) '.';
    position: absolute;
    left: 0;
    top: 1.1rem;

    color: $color-dark;

    font-family: $font-stack-secondary;
    font-weight: 500;
  }
}

.quote {
  text-align: center;
  @include boxed(50rem);
  p {
    position: relative;

    font-family: $font-stack-secondary;
    font-style: italic;
    font-size: 1.75em;

    line-height: 1.525;
  }
  p:before {
    content: "\201C";
    position: absolute;
    transform: translateX(-100%);
    margin-left: -0.25rem;
  }
  p:after {
    content: "\201D";
    position: absolute;
    margin-left: 0.25rem;
  }
  cite {
    display: inline-block;
    margin-top: 1rem;
    color: rgba(white, 0.4);

    font-style: normal;
    font-weight: 300;
    font-size: 0.875em;
  }
}

.member-block {
  position: relative;
  padding: 2rem;
  background: white;
  & + & {
    margin-top: 30px;
  }
  &-header {
    position: relative;
    display: flex;
    align-items: center;

    transition: margin 300ms ease;
  }
  &-header:hover {
    cursor: pointer;
  }
  &-header.active {
    margin-bottom: -1rem;
  }
  &-header > span {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    cursor: pointer;
  }
  &-header > span:before,
  &-header > span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 1rem;
    width: 2px;
    height: 100%;
    background: $color-dark;
    display: block;
  }
  &-header > span:before {
    transition: transform 300ms ease;
    transform-origin: center center;
  }
  &-header.active > span:before {
    transform: scaleY(0);
  }
  &-header > span:after {
    transform: rotate(-90deg);
  }
  &-header-thumb {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 2rem;
  }
  &-header-caption {
    .name {
      font-family: $font-stack-secondary;
      font-weight: 600;
      font-size: 1.5em;

      line-height: 1.1;
    }
    .position {
      font-size: 1em;
      font-weight: 300;

      opacity: 0.5;
    }
  }
  &-body {
    position: relative;
    z-index: 2;
    max-height: 0;
    overflow: hidden;
    transition: max-height 300ms ease;
  }
  &-body-content {
    padding-left: calc(8rem + 2rem);
    p {
      margin-bottom: 0;
      font-size: 1em;
      font-weight: 300;
    }
    p + p {
      margin-top: 0.75rem;
    }
  }
}

.contact-details {
  margin-bottom: 0;
  font-weight: 300;
  font-size: 1.125em;
  line-height: 1.525;

  text-align: center;
  a {
    color: #3971FF;
  }
  a:hover {
    text-decoration: underline;
  }
}

#googleMap {
  height: 720px;

  margin-top: 5rem;
  margin-bottom: -5rem;

  margin-left: -15px;
  margin-right: -15px;
}

.portfolio {
  display: flex;
  flex-flow: row wrap;
  margin: -15px;
  &-item {
    @include flex-grid(2);
    padding: 15px;
  }
}

.portfolio-block {
  position: relative;
  overflow: hidden;
  display: block;
  &-thumb {
    padding-bottom: 120%;

    background-color: #f5f5f5;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  &:hover &-thumb {
    transform: scale(1.05);
  }
  &-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 85%;
    background: white;
    padding: 2rem;
    h3 {
      font-size: 1.5em;
      font-weight: 600;
    }
    p {
      margin-bottom: 0;
      margin-top: 0.5rem;
      font-weight: 300;
    }
    .action {
      position: relative;
      font-weight: 500;
      display: inline-block;
      margin-top: 1rem;
      color: $color-dark;
    }
    .action:after {
      content: "\f054";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      margin-top: -1px;
      margin-left: 0.75rem;
      font-family: "Font Awesome 5 Free";
      font-weight: 700;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.65rem;
      padding-top: 1px;
      padding-left: 1px;
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid rgba($color-dark, 0.3);
    }
  }
  .meta {
    display: flex;
    margin: 0 -0.75rem;
    margin-bottom: 1rem;
  }
  .meta > div {
    position: relative;
    padding: 0 0.75rem;
  }
  .meta > div:first-child:after {
    content: "";
    position: absolute;
    top: 5px;
    right: -0.125rem;
    width: 0.25rem;
    height: 0.25rem;
    background: rgba($color-dark, 0.3);
    border-radius: 50%;
  }
  .category {
    text-transform: uppercase;
    font-size: 0.65em;
    letter-spacing: 2px;

    color: rgba($color-dark, 0.6);
  }
  .date {
    text-transform: uppercase;
    font-size: 0.65em;
    letter-spacing: 2px;

    color: rgba($color-dark, 0.6);
  }
}

.case-article {
  //padding-bottom: 4rem;
  &-cover {
    position: relative;
    overflow: hidden;

    background-color: $color-dark;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    height: 30vw;
    min-height: 400px;
  }
  &-header {
    max-width: 66rem;

    margin-left: auto;
    margin-right: auto;

    margin-top: -10rem;
    margin-bottom: 1rem;

    position: relative;
    z-index: 2;

    background-color: white;
    padding: 3rem 15px;
  }
  &-title {
    font-size: 2.5em;
    font-weight: 600;

    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  &-short {
    font-size: 1.25em;
    font-weight: 600;

    line-height: 1.6;

    position: relative;
    padding-left: 6rem;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 1rem;

      height: 2px;
      width: 4rem;

      background-color: $color-dark;
    }
  }
  &-meta {
    display: flex;

    font-weight: 700;
    font-size: 0.75em;

    text-transform: uppercase;

    margin: 0 -0.75rem;

    position: relative;
    > div {
      padding: 0.75rem;
    }
  }
  &-tag {
    position: relative;

    font-weight: 500;
  }
  &-body {
    max-width: 66rem;
    margin-left: auto;
    margin-right: auto;

    padding: 0 15px;
    p {
      margin-bottom: 0;

      font-size: 1.125em;
      font-weight: 300;
    }
    p + p {
      margin-top: 1.5rem;
    }
    * + p {
      margin-top: 1.5rem;
    }
    * + h2 {
      margin-top: 3rem;
    }
    * + h3 {
      margin-top: 3rem;
    }
    h2 + * {
      margin-top: 2rem;
    }
    h3 + * {
      margin-top: 1rem;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 600;

      color: $color-primary;
    }
    h3 {
      font-size: 1.25em;
      font-weight: 600;
    }
    img {
      margin: 1.5rem 0;
      display: block;

      max-width: 100%;
      min-width: 100%;
    }
    u {
      text-underline-position: under;
    }
  }
  &-share {
    max-width: 60rem;

    margin-left: auto;
    margin-right: auto;

    margin-top: 2rem;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      text-align: center;
    }
    li {
      display: inline-block;

      padding-right: 0.25rem;
    }
    li a {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      width: 2.5rem;
      height: 2.5rem;

      border-radius: 50%;

      font-size: 0.875rem;

      border: 1px solid rgba($color-dark, 0.1);
      color: rgba($color-dark, 0.4);

      transition: all .3s ease;
    }
    li a i {
      position: relative;
      z-index: 2;
    }
    .ic-facebook:before {
      background-color: #3b5998;
    }
    .ic-twitter:before {
      background-color: #1da1f2;
    }
    .ic-instagram:before {
      background-color: #e1306c;
    }
    .ic-medium:before {
      background-color: #00ab6c;
    }
    li a:hover:before {
      transform: scaleY(1);
    }
    li a:hover {
      background-color: $color-primary;
      color: white;
    }
  }
  &-statement {
    margin: 2rem 0 4rem;
    h2 {
      font-size: 2em;
    }
  }
  &-mockup-wide {
    margin: 3rem 0;
    img {
      margin-left: auto;
      margin-right: auto;

      display: block;
    }
  }
}

.result {
  margin-bottom: 3rem;
  &-value {
    font-weight: 700;
    font-family: $font-stack-secondary;
    font-size: 2em;

    color: $color-primary;

    line-height: 1.25;
  }
  &-caption {
    font-size: 0.75em;
    font-weight: 400;
  }
}

.mission-values {
  display: flex;
  flex-flow: row wrap;
  margin: 2rem -15px -15px;
  &-item {
    @include flex-grid(1);
    padding: 15px;
  }
  &-item:last-child {
    flex: 1;
    max-width: none;
  }
  &-block {
    position: relative;
    h6 {
      font-size: 1.25em;
      font-weight: 600;
    }
    p {
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
  }
}

/*** Main End ***/

/*** Footer Start ***/

footer {
  background: darken($color-primary, 5%);
  color: white;
  padding: 2rem 0;
  a {
    color: white;
  }
  a:hover {
    color: white;
  }
}

.footer-social {
  list-style: none;
  padding: 0;
  margin: 0 -0.125rem;
  li {
    display: inline-block;
    padding: 0 0.125rem;
  }
  li a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 2rem;
    height: 2rem;
    background: lighten($color-primary, 5%);
  }
  li a:hover {
    background: lighten($color-primary, 10%);
  }
  li a i {
    font-size: 1rem;
  }
}

.copyright {
  color: #797c86;
  font-size: 0.875em;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    padding: 0 0.25rem 0 0.25rem;
  }
  li a {
    color: #797c86;
    font-size: 0.875em;
  }
  li a:hover {
    color: white;
  }
}

/*** Footer End ***/