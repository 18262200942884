/*************************************************************
 Author     : Vivid Motion @ http://vividmotion.co/
 *************************************************************/

@import 'custom';

@media all and (min-width: 576px) {
  //VARIABLES//



  //VARIABLES END//

  .clients {
    &-item {
      @include flex-grid(2);
    }
  }
}

@media all and (min-width: 768px) {
  //VARIABLES//

  $header-padding: 110px;

  //VARIABLES END//

  .navbar-expand-md {
    padding: 2rem 0;
  }

  .navbar-nav {
    align-items: center;
    .nav-item {
      margin: 0 0 0 2.5rem;
    }
    .nav-item > .nav-link {
      padding: 0;

      font-size: 0.825em;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: uppercase;

      line-height: 1.725;
    }
    .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: transparent;
    }
    .nav-item.active > .nav-link:before {
      background-color: currentColor;
    }
  }

  #hero-home {
    > .d-flex {
      min-height: 70vh;
    }
  }

  #hero-contact {
    > .d-flex {
      min-height: 50vh;
    }
  }

  #hero-portfolio {
    > .d-flex {
      min-height: 50vh;
    }
  }

  .hero {
    > .d-flex {
      min-height: 100vh;
      padding-top: calc(2.5rem + #{$header-padding});
      padding-bottom: calc(5rem + 3rem);
    }
  }

  body {
    //padding-top: $header-padding;
  }

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .row {
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;
    > .col,
    > [class*="col-"] {
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }
  }
}

@media all and (min-width: 1024px) {
  //VARIABLES//



  //VARIABLES END//

  #section-contact {
    .btn {
      display: block;
    }
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .clients {
    &-item {
      @include flex-grid(4);
    }
    &-item:nth-child(-n + 4) &-block {
      border-top: 0;
    }
    &-item:nth-child(4n + 4) &-block {
      border-right: 0;
    }
    &-item:nth-child(2n + 2) &-block {
      border-right: 1px solid #eaeaea;
    }
  }

  .how {
    &-item {
      @include flex-grid(3);
    }
  }

  .swiper-testimonials-wrapper {
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid #eaeaea;
  }

  .testimonial {
    &-content p {
      font-size: 1.25em;
    }
    &-cite {
      cite {
        font-size: 1.25em;
      }
    }
  }

  .section-heading {
    h2 {
      font-size: 3em;
    }
    p {
      font-size: 1.25em;
    }
  }

  .section-content {
    h2 {
      font-size: 3em;
    }
    p {
      font-size: 1em;
    }
  }
}

@media all and (min-width: 1280px) {
  //VARIABLES//



  //VARIABLES END//

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .row {
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;
    > .col,
    > [class*="col-"] {
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }
  }

  @for $i from 1 through 10 {
    .mt-#{$i} {
      margin-top: $i + rem !important;
    }
    .mb-#{$i} {
      margin-bottom: $i + rem !important;
    }
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .case-article {
    &-header {
      padding: 3rem;
    }
    &-body {
      padding: 0 3rem;
    }
    &-title {
      font-size: 3em;
    }
  }
}

@media all and (min-width: 1440px) {
  //VARIABLES//



  //VARIABLES END//
}

@media all and (min-width: 1650px) {
  //VARIABLES//



  //VARIABLES END//

  //Grid mods
  $grid-gutter: 15px;

  .container {
    padding-left: $grid-gutter;
    padding-right: $grid-gutter;
  }

  .row {
    margin-left: -$grid-gutter;
    margin-right: -$grid-gutter;
    > .col,
    > [class*="col-"] {
      padding-left: $grid-gutter;
      padding-right: $grid-gutter;
    }
  }
}

@media all and (max-width: 1199px) {

}

@media all and (max-width: 1024px) {

}

@media all and (max-width: 768px) {

}

@media all and (max-width: 767px) {
  h1 {
    font-size: 2.4em;
    line-height: 1;
  }

  h2 {
    font-size: 2em;
  }

  h3 {
    font-size: 1.8em;
  }

  h4 {
    font-size: 1.6em;
  }

  h5 {
    font-size: 1.4em;
  }

  h6 {
    font-size: 1.2em;
  }

  .navbar-menu {
    position: absolute;
    top: 100%;

    right: 1em;
    left: 1em;

    background-color: white;
    border: 1px solid #eaeaea;

    padding: 5px 0;

    margin-top: 10px;

    border-radius: 0.25rem;

    display: none;

    text-align: center;

    opacity: 0;
    transition: opacity .3s ease;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;

    text-align: left;
    .nav-item > .nav-link {
      padding: 8px 16px;

      margin: 0 -1px;

      font-size: 0.875em;

      color: black;

      transition: all .2s ease;
      .original-hover div,
      .fake-hover div {
        transition: none;
      }
    }
    .nav-item > .nav-link:hover {
      background-color: $color-primary;
      color: white;
    }
    .nav-item > .btn {
      display: block;

      margin-bottom: -6px;

      border-radius: 0 0 0.25rem 0.25rem;
    }
  }

  .navbar-toggler {
    order: 1;
    margin-left: auto;
    border: 0;
    padding: 0;

    border-radius: 0;
    &:hover {
      background-color: black;
      .navbar-toggler-icon:before {
        color: white;
      }
    }
  }

  .navbar-toggler,
  .navbar-brand {
    z-index: 1061;
  }

  .menu-open {
    opacity: 1;
  }

  .navbar-toggler-icon {
    position: relative;

    border-radius: 0;
  }

  .menu-is-open {
    .navbar-toggler-icon {
      background-color: black;
    }
    .navbar-toggler-icon:before {
      color: white;
    }
    .navbar-toggler:hover .navbar-toggler-icon:before {
      color: white;
    }
  }

  .navbar-toggler-icon:before {
    content: "\f141";
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;

    color: white;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .member-block-header-thumb {
    width: 5rem;
    height: 5rem;
    margin-right: 1rem;
  }

  .member-block-header > span {
    width: 1rem;
    height: 1rem;
  }

  .member-block + .member-block {
    margin-top: 15px;
  }

  .member-block {
    padding: 1rem;
  }

  .member-block-header > span:before, .member-block-header > span:after {
    left: 0.5rem;
  }

  .member-block-body-content {
    padding-left: 0;
    padding-top: 3rem;
  }

  .member-block-body-content p {
    font-size: 1em;
  }

  .member-block-header-caption {
    line-height: 1;
    padding-right: 3rem;
  }

  .member-block-header-caption .name {
    font-size: 1em;
  }

  .member-block-header-caption .position {
    font-size: 0.75em;
    line-height: 1.425;
  }
}

@media all and (max-width: 576px) {

}

@media all and (max-width: 320px) {

}